import { createRouter, createWebHistory } from 'vue-router';
import LayoutPadraoo from "../components/layouts/LayoutPadrao.vue";
import { auth } from '../firebase';

const routesPadrao = [
  {
    path: '',
    name: 'cadastroAssociado',
    meta: { requiresAuth: true },
    component: LayoutPadraoo,
    children: [
      {
        path: '/home', 
        name: "TelaInicial",
        component: () => import('../views/login/TelaInicial.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/cadastro-pessoa/:id?', 
        name: "CadastroUsuarioInterno",
        component: () => import('../views/usuario/CadastroUsuarioInterno.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/cadastro-medicamento/:id?', 
        name: "MedicamentoCadastro",
        component: () => import('../views/medicamento/MedicamentoCadastro.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/pagamento/:id?', 
        name: "PagamentoUsuario",
        component: () => import('../views/pagamento/PagamentoUsuario.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/cadastro-usuario/:id?',
        name: "CadastroUsuario",
        component: () => import('../views/usuario/CadastroUsuarioInterno.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/listar-pessoa/:id?', 
        name: "ListarUsuario",
        component: () => import('../views/usuario/ListarUsuario.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/listar-medicamento/:id?', 
        name: "ListarMedicamento",
        component: () => import('../views/medicamento/ListarMedicamento.vue'),
        meta: { requiresAuth: true },
      },
			{
				path: '/calendario',
        name: "AgendaCalendario",
				component: () => import('../views/calendario/CalendarioView.vue'),
        meta: { requiresAuth: true },
			}
    ]

  }
];
const routesSemFundo = [
	{
		path: "/login",
    name: 'telaLogin',
		children: [
			{
				path: '/login',
        name: "LoginUsuario",
				component: () => import('../views/login/TelaLogin.vue'),
			}
		],
	},
];


const routes = routesPadrao.concat(routesSemFundo);

const router = createRouter({
  history: createWebHistory("/aom-web-admin"),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
  if (requiresAuth) {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        if (to.path !== '/login') {
          localStorage.setItem('lastRoute', to.fullPath);
        }
        next();
      } else {
        next('/login');
      }
      unsubscribe();
    });
  } else {
    next();
  }
});





export default router;
