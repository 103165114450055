<template>
	<v-app class="bg-surface">
		<v-main class="v-main" style="--v-layout-left: 0px; --v-layout-right: 0px; --v-layout-top: 10px; --v-layout-bottom: 0px;">
			<v-container>
				<div class="logo-container">
					<img src="@/assets/LogoAOM.png" alt="Logo" class="logo" />
				</div>
				<v-row class="flex-container">
					<div class="sidebar">
						<BarraLateral />
					</div>
					<div class="content">
						<router-view/>
					</div>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
	import BarraLateral from "@/components/navegacao/BarraLateral.vue";
	import logo from "@/assets/LogoAOM.png";

	export default {
		components: {
			BarraLateral,
		},
		data() {
			return {
				logo,
			};
		},
	};
</script>
<style scoped>
	.custom-container {
		min-height: 100vh;
		background: #ECF1F0;
	}

	.flex-container {
		display: flex;
		flex-wrap: nowrap;
	}

	.logo-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		pointer-events: none; /* Permite que outros elementos sejam clicáveis acima dela */
	}

	.logo {
		width: 300px; /* Tamanho ajustável */
		opacity: 0.1; /* Transparência baixa para um efeito de fundo */
	}

	div :deep(.content) {
		flex: 1;
		background: white;
		border-radius: 20px;
		min-height: 90vh;
		margin-left: 16px;
		padding: 16px;
		overflow: hidden;
		z-index: 2;
	}

	.sidebar {
		flex: 0 0 auto;
		background-color: white;
		padding: 16px;
		border-radius: 20px;
		max-width: 250px;
	}
</style>


