<template>
	<div>
	<AppBar @toggle-sidebar="trocarEstadoSidebar" />
	<v-navigation-drawer
		v-model="drawerMobile"
		:permanent="!isMobile"
		:temporary="isMobile"
		elevation="4"
		:color="$vuetify.theme.current.dark ? 'dark' : 'white'"
	>
		<div class="container">
		<v-img :src="logoBranca" class="imagem" />
		</div>
		<v-list nav>
		<v-list-item>
			<!-- Exibe o nome e email do usuário logado -->
			<v-list-item-content>
			<v-list-item-title>{{ userName }}</v-list-item-title>
			<v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
		<template v-for="(menu, index) in menus" :key="index">
			<v-list-item
			v-if="!menu.items"
			:prepend-icon="menu.icone"
			:title="menu.titulo"
			@click="onClickMenu(menu)"
			/>
			<v-list-group v-else>
			<template v-slot:activator="{ props }">
				<v-list-item v-bind="props" :prepend-icon="menu.icone" :title="menu.titulo" />
			</template>
			<v-list-item
				v-for="(submenu, indexSubmenuItem) in menu.items"
				:key="indexSubmenuItem + 'submenu-item'"
				:value="submenu.titulo"
				:title="submenu.titulo"
				:prepend-icon="submenu.icone"
				@click="onClickMenu(submenu)"
			/>
			</v-list-group>
		</template>
		</v-list>
	</v-navigation-drawer>
	</div>
</template>
  

<script>
import AppBar from "@/components/navegacao/AppBar.vue"; // Certifique-se de importar o componente AppBar
import logoBranca from "@/assets/LogoAOM.png";
import logo from "@/assets/logo.png";

export default {
  name: "BarraLateral",
  components: {
    AppBar,
  },
  data() {
    return {
      logo,
      logoBranca,
      drawerMobile: false, // Controla a visibilidade da barra lateral
      menus: [
        { titulo: "Página inicial", icone: "mdi-home", to: "/home" },
        {
          titulo: "Cadastro de Pessoas",
          icone: "mdi-account-group",
          to: "/",
          items: [
            { titulo: "Pagamento", to: "/pagamento" },
            { titulo: "Pessoas", to: "/cadastro-pessoa" },
            { titulo: "Medicamento", to: "/cadastro-medicamento" }
          ],
        },
        {
          titulo: "Listar dados",
          icone: "mdi-account-group",
          to: "/",
          items: [
            { titulo: "Pessoas", to: "/listar-pessoa" },
            { titulo: "Medicamento", to: "/listar-medicamento" },
          ],
        },
        {
          titulo: "Agenda",
          icone: "mdi-calendar",
          to: "/calendario",
        },
        {
          titulo: "Sair",
          icone: "mdi-logout",
          click: this.logout,
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return !this.$vuetify.display.mdAndUp;
    },
    userEmail() {
      return this.$store.state.user.email; // Ajuste para a store do Vuex
    },
    userName() {
      return this.$store.state.user.name; // Ajuste para a store do Vuex
    },
  },
  methods: {
    trocarEstadoSidebar() {
      this.drawerMobile = !this.drawerMobile; // Alterna o estado da barra lateral
    },
    onClickMenu(menu) {
      if (menu.to) {
        this.$router.push(menu.to);
      } else if (menu.click) {
        menu.click();
      }
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
        this.$router.push("/login");
      } catch (error) {
        console.error("Erro ao sair:", error);
      }
    },
  },
};
</script>
<style scoped>
.nome-empresa {
  margin-top: 20px;
}
.v-navigation-drawer:deep() {
  border-top-right-radius: 20px;
  padding-bottom: 56px;
  border: none !important;
}
:deep(.v-list) {
  background: transparent;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imagem {
  height: 90px;
  width: 90px;
  margin-top: 25px;
}
</style>
