<template>
	<v-app-bar class="app-bar" height="80" color="blue darken-2" :elevation="4" rounded ref="appBar">
	<v-btn icon @click="trocarEstadoSidebar">
		<v-icon>mdi-menu</v-icon> <!-- Botão de menu para abrir a barra lateral -->
	</v-btn>
	<v-img :src="logoBranca" contain height="70" max-width="160px" class="logo ml-4" />
	<span class="logo-text">Associação Obra de Misericórdia Administrativo</span>
	<v-spacer />
	</v-app-bar>
</template>
<script>
import logoBranca from "@/assets/LogoAOM.png";

export default {
  data() {
    return {
      logoBranca,
      sidebarOpen: false,
    };
  },
  methods: {
    trocarEstadoSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
      this.$emit("toggle-sidebar", this.sidebarOpen);
    },
    handleClickOutside(event) {
      // Checa se a barra lateral está aberta e se o clique foi fora dela
      if (this.sidebarOpen) {
        const appBar = this.$refs.appBar.$el; // Acesso ao DOM real do v-app-bar
        if (appBar && !appBar.contains(event.target)) {
          this.sidebarOpen = false;
          this.$emit("toggle-sidebar", this.sidebarOpen);
        }
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  background-color: #1b6caf;
  display: flex;
  align-items: center;
  padding: 0 16px;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  .logo {
    margin-left: 8px;
    height: 70px;
    max-width: 160px;
  }

  .logo-text {
    margin-left: 20px;
    font-size: 24px;
    color: white;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
  }

  &:deep(.v-btn) {
    text-transform: uppercase;
    color: white;
  }

  @media (max-width: 600px) {
    .logo-text {
      font-size: 18px;
      margin-left: 12px;
    }
    .logo {
      height: 50px;
      max-width: 120px;
    }
  }
}
</style>
